import axios from 'axios'
import store from '@/store'
import router from '@/router'
//  import qs from 'qs'

const result = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // paramsSerializer: function (params) {
  //   return qs.stringify(params, { arrayFormat: 'brackets' })
  // },
})

// 添加请求拦截器
result.interceptors.request.use(
  function (config) {
    // 返回设置
    if (store.getters['user/isLogin']) {
      config.headers.Authorization = `Bearer ${store.state.user.userInfo.data.access_token}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

// response 拦截器
result.interceptors.response.use(
  function (response) {
    // return response.data
    switch (response.data.code) {
      case 200:
        return response.data
      case 401:
        // 401为未登录状态，一律跳转到登录页
        // throw new Error(response.data.message)
        router.replace({
          name: 'login',
        })
        break
      default:
        return response.data.message
      // ElMessage();
      // throw new Error(response.data.message)
    }
  },
  function (error) {
    return Promise.reject(error)
  },
)


export default result