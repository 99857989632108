import { createApp } from 'vue'

import app from './app.vue'
import router from './router'
import store from './store'
import utils from './utils';
import "./utils/gt";
//import request from './utils/httpRequest';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import request from './utils/request';
import appImg from '@/components/app-img'
import appButton from '@/components/app-button'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import "overlayscrollbars/css/OverlayScrollbars.css";
// import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
// 日期组件中文
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
dayjs.locale('zh-cn');
dayjs.extend(require('dayjs/plugin/localeData'));
const monitor = createApp(app)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    monitor.component(key, component)
}
monitor
    .use(store)
    .use(router)
    .use(ElementPlus, {
        locale: zhCn,
        size: 'large'
    })
    .component('app-img', appImg)
    .component('app-button', appButton)
    // .component("overlayScrollbars", OverlayScrollbarsComponent)
    .mount('#app');

monitor.config.globalProperties.$utils = utils;
monitor.config.globalProperties.$dayjs = dayjs;
monitor.config.globalProperties.$request = request;