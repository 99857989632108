import { createRouter, createWebHashHistory } from "vue-router";
import store from '@/store'

const routes = [
  {
    path: '/',
    component: () => import('@/views'),
    meta: {
      title: '主页'
    },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home.vue'),
        meta: {
          auth: {
            required: true,
          },
        }
      },
      {
        path: '/scenicSpot',
        component: () => import('@/views/scenicSpot.vue'),
        name: 'scenicSpot',
        meta: {
          auth: {
            required: true,
          },
        }
      },
      {
        path: '/restaurant',
        component: () => import('@/views/restaurant.vue'),
        name: 'restaurant',
        meta: {
          auth: {
            required: true,
          },
        }
      },
      {
        path: '/hotel',
        component: () => import('@/views/hotel.vue'),
        name: 'hotel',
        meta: {
          auth: {
            required: true,
          },
        }
      },
      {
        path: '/food',
        component: () => import('@/views/food.vue'),
        name: 'food',
        meta: {
          auth: {
            required: true,
          },
        }
      },
      {
        path: '/shopping',
        component: () => import('@/views/shopping.vue'),
        name: 'shopping',
        meta: {
          auth: {
            required: true,
          },
        }
      },
      {
        path: '/venue',
        component: () => import('@/views/venue.vue'),
        name: 'venue',
        meta: {
          auth: {
            required: true,
          },
        }
      },
      {
        path: '/hongpei',
        component: () => import('@/views/hongpei.vue'),
        name: 'hongpei',
        meta: {
          auth: {
            required: true,
          },
        }
      }
    ]
  },

  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/common/400.vue'),
    name: '400',
  },
  {
    path: '/login',
    component: () => import('@/views/common/login.vue'),
    name: 'login',
  },

];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// 加载钩子判断权限
router.beforeEach(async (to, from, next) => {

  // 检查路由是否要求登录
  if (to?.meta?.auth?.required) {

    // 如果路由要求登录，那么检查是否已经登录
    if (store.getters['user/isLogin']) {

      // // 获取权限
      // const auth = store.state.user.userInfo.auth;

      // // 已登录则判断权限列表
      // if (auth?.length) {
      //   // 有权限列表继续跳转
      //   routerCheck(to, next);
      // } else {
      //   // 没有权限列表跳转登录页
      //   next({
      //     name: "login"
      //   })

      //   // 提示
      //   ElMessageBox.alert('您的权限组中没有权限，请联系管理员添加', '警告')
      // }
      next();
    } else {
      // 记录来源页面

      console.log(to.name)
      store.commit('user/loginBackUrl', to.name)

      // 未登录则跳转登录页
      next({
        name: "login"
      })
    }
  } else {

    // 不要求登录则直接继续
    next()
  }
})
export default router;

